import React from 'react'
import { Link } from 'gatsby'

import styles from './tag-list.module.css'

export default ((tagList) => {
  const tags = tagList.tags
  const createTagsElement = () => {
    if (!Array.isArray(tags)) return;

    return tags.map((tag, i) => {
      return <p key={tag.slug} className={styles.tagSmall}><Link to={`/tags/${tag.slug}`} className={styles.tagText}>{tag.name}</Link></p>
    })
  }

  return (
    <div className={styles.tagsWrap}>
      {createTagsElement()}
    </div>
  )
})

