import React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import Img from "gatsby-image"

export default (props) => {
  return (
    <StaticQuery
      query={
        graphql`
        query ImageQuery {
          allFile(filter: {ext: {regex: "/(png|jpg)/"}}) {
            edges {
              node {
                extension
                relativePath
                childImageSharp {
                  fluid(maxWidth: 2000, quality: 90) {
                    ...GatsbyImageSharpFluid 
                  }
                }
              }
            }
          }
        }`
      }
      render={(data) => {
        const imageName = props.imageName
        const imageSource = data.allFile.edges.find(image => image.node.relativePath ===imageName)
        return (
          <>
            <Img
              fluid={imageSource.node.childImageSharp.fluid}
              alt={props.alt}
            />
          </>
        )
      }}
    />
  )
}